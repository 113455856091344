import {
  addFileOrDocTag,
  deleteFileOrDocTag,
  exportDeposition,
  exportTimelineEvents,
  getChartData,
  getDepositionSummary,
  getDepositionTopics,
  getEvents,
  getFileById,
  getFileUrl,
  getMatterById,
  getMatters,
  updateDocument,
  getTimelineFilters,
  updateTimelineEvent,
  updateFact,
  updateMedEvent,
  getLibrary,
  getTasks,
  getMedicalSummary,
  getMedicalSummaryEvents,
  exportMedSummary
} from '@/http/services';
import { EventsFilters, Library } from '@/utils/types';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

interface AddTagProps {
  fileId: number;
  tag: { value: string; type: string };
  documentId?: number;
  onSuccess?: () => void;
}

export const useGetMatters = () => {
  const { data, error, mutate, isLoading } = useSWR(
    'useGetMatters',
    getMatters,
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetMattersMutation = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    'useGetMattersMutation',
    getMatters
  );
  return { isLoading: isMutating, error, mutate: trigger };
};

export const useGetMatterById = ({ matterId }: { matterId: string }) => {
  const { data, error, mutate, isLoading } = useSWR(
    matterId ? 'useGetMatterById' : null,
    () => getMatterById(matterId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetDepositionSummary = (depoId: string) => {
  const { data, error, mutate, isLoading } = useSWR(
    `useGetDepositionSummary-${depoId}`,
    () => getDepositionSummary(depoId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetDepositionTopics = (depoId: string) => {
  const { data, error, mutate, isLoading } = useSWR(
    `useGetDepositionTopics-${depoId}`,
    () => getDepositionTopics(depoId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetFileUrl = (fileId?: number) => {
  const { data, error, mutate, isLoading } = useSWR(
    fileId ? 'useGetFileUrl' : null,
    () => fileId && getFileUrl(fileId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetFileUrlMutation = (fileId?: number) => {
  const { error, trigger, isMutating } = useSWRMutation(
    'useGetFileUrlMutation',
    () => fileId && getFileUrl(fileId)
  );
  return { isLoading: isMutating, error, trigger };
};

export const useGetFileById = (matterId: string, fileId?: number) => {
  const { data, error, mutate, isLoading } = useSWR(
    fileId ? 'useGetFileById' : null,
    () => fileId && getFileById(fileId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useAddFileOrDocTag = ({
  fileId,
  tag,
  documentId,
  onSuccess
}: AddTagProps) => {
  const { trigger, isMutating, error } = useSWRMutation(
    'useAddFileOrDocTag',
    () => addFileOrDocTag(fileId, tag, documentId),
    {
      onSuccess: () => onSuccess && onSuccess()
    }
  );
  return {
    addTag: trigger,
    isLoading: isMutating,
    error
  };
};

export const useDeleteFileOrDocTag = ({
  fileId,
  tagId,
  documentId,
  onSuccess
}: {
  fileId: number;
  tagId?: number;
  documentId?: number;
  onSuccess?: () => void;
}) => {
  const { trigger, isMutating, error } = useSWRMutation(
    'useDeleteFileOrDocTag',
    () => tagId && deleteFileOrDocTag(fileId, tagId, documentId),
    {
      onSuccess: () => onSuccess && onSuccess()
    }
  );
  return {
    deleteTag: trigger,
    isLoading: isMutating,
    error
  };
};

export const useUpdateTimelineEvent = (onSuccess?: () => void) => {
  const { trigger, isMutating, error } = useSWRMutation(
    'useUpdateTimelineEvent',
    updateTimelineEvent,
    {
      onSuccess: () => onSuccess && onSuccess()
    }
  );
  return {
    updateTimelineEvent: trigger,
    isLoading: isMutating,
    error
  };
};

export const useUpdateDocument = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    'useUpdateDocument',
    updateDocument
  );
  return {
    updateDocument: trigger,
    isLoading: isMutating,
    error
  };
};

export const useGetEvents = (
  matterId: string,
  filters: Omit<EventsFilters, 'pageSize' | 'page'>
) => {
  const { data, error, mutate, isLoading } = useSWR(
    ['useGetEvents', matterId, filters],
    getEvents,
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetTimelineFilters = (matterId: string) => {
  const { data, error, mutate, isLoading } = useSWR(
    'useGetTimelineFilters',
    () => getTimelineFilters(matterId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetChartData = (matterId: string) => {
  const { data, error, mutate, isLoading } = useSWR(
    'useGetChartData',
    () => getChartData(matterId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useExport = (
  id: string,
  type: 'deposition' | 'med' | 'timeline'
) => {
  const key =
    type === 'deposition' ? 'useDepositionExport' : 'useMedSummaryExport';

  const method = type === 'deposition' ? exportDeposition : exportMedSummary;

  const { data, trigger, isMutating, error } = useSWRMutation(
    [key, id],
    method
  );

  return { data, trigger, isMutating, error };
};

export const useTimelineExport = (
  matterId: string,
  filters?: Omit<EventsFilters, 'pageSize' | 'page'>
) => {
  const { data, trigger, isMutating, error } = useSWRMutation(
    ['useTimelineExport', matterId, filters],
    exportTimelineEvents
  );
  return { data, trigger, isMutating, error };
};

export const useUpdateFact = () => {
  const { trigger, isMutating } = useSWRMutation('useUpdateFact', updateFact);
  return { trigger, isMutating };
};

export const useGetLibrary = (matterId: number, parentId?: number) => {
  const { data, error, mutate, isLoading } = useSWR<Library>(
    `useGetLibrary-${parentId}`,
    () => getLibrary(matterId, parentId),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetTasks = (
  orgId: number,
  email: string,
  showCompleted = false
) => {
  const { data, error, mutate, isLoading } = useSWR(
    `useGetTasks-${orgId}-${email}`,
    () => getTasks(showCompleted),
    {
      revalidateOnFocus: false
    }
  );

  let err = error;
  if (data?.message) {
    err = data.message;
  }

  return { data, isLoading, error: err, mutate };
};

export const useGetMedicalSummary = (id: string) => {
  const { data, error, mutate, isLoading } = useSWR(
    `useGetMedicalSummary-${id}`,
    () => getMedicalSummary(id),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetMedicalSummaryEvents = (id: string) => {
  const { data, error, mutate, isLoading } = useSWR(
    `useGetMedicalEvents-${id}`,
    () => getMedicalSummaryEvents(id),
    {
      revalidateOnFocus: false
    }
  );
  return { data, isLoading, error, mutate };
};

export const useUpdateMedEvent = () => {
  const { trigger, isMutating } = useSWRMutation(
    'useUpdateMedicalEvent',
    updateMedEvent
  );
  return { trigger, isMutating };
};
