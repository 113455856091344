import { fetchWithAuth } from '@/http/auth';
import {
  DepositionSummary,
  DepositionTopic,
  EventsFilters,
  MedicalSummary,
  MedicalSummaryEvent
} from '@/utils/types';
import { getQueryFilters } from '@/http/helpers';

export const getMatters = async () => {
  const res = await fetchWithAuth('/matters');
  return res;
};

export const getEvents = async ([_, matterId, filters]: [
  _: string | null,
  matterId: string,
  filters?: Omit<EventsFilters, 'pageSize' | 'page'>
]) => {
  const queryFilters = getQueryFilters(filters);

  const res = await fetchWithAuth(`/matters/${matterId}/events${queryFilters}`);

  return res;
};

export const getTimelineFilters = async (matterId: string) => {
  const res = await fetchWithAuth(`/matters/${matterId}/timeline_filters`);
  return res;
};

export const getMatterById = async (matterId: string) => {
  const res = await fetchWithAuth(`/matters/${matterId}`);
  return res;
};

export const getDepositionSummary = async (
  deposition_id: string
): Promise<DepositionSummary> => {
  const res = await fetchWithAuth(`/depositions/${deposition_id}`);
  return res;
};

export const getDepositionTopics = async (
  deposition_id: string
): Promise<DepositionTopic[]> => {
  const res = await fetchWithAuth(`/depositions/${deposition_id}/topics`);
  return res;
};

export const getFileUrl = async (fileId: number) => {
  const res = await fetchWithAuth(`/files/${fileId}/url`);
  return res;
};

export const getFileById = async (fileId: number) => {
  const res = await fetchWithAuth(`/files/${fileId}`);
  return res;
};

export const addFileOrDocTag = async (
  fileId: number,
  tag: { value: string; type: string },
  documentId?: number
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(tag)
  };
  if (documentId) {
    const res = await fetchWithAuth(`/documents/${documentId}/tags`, options);
    return res;
  }
  const res = await fetchWithAuth(`/files/${fileId}/tags`, options);
  return res;
};

export const deleteFileOrDocTag = async (
  fileId: number,
  tagId: number,
  documentId?: number
) => {
  if (documentId) {
    const res = await fetchWithAuth(`/documents/${documentId}/tags/${tagId}`, {
      method: 'DELETE'
    });
    return res;
  }
  const res = await fetchWithAuth(`/files/${fileId}/tags/${tagId}`, {
    method: 'DELETE'
  });
  return res;
};

export const updateTimelineEvent = async (
  _: string,
  {
    arg
  }: {
    arg: {
      eventId: number;
      isHidden?: boolean;
      description?: string;
    };
  }
) => {
  return await fetchWithAuth(`/events/${arg.eventId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      is_hidden: arg.isHidden,
      description: arg.description
    })
  });
};

export const getChartData = async (matterId: string) => {
  const res = await fetchWithAuth(`/matters/${matterId}/charts`);
  return res;
};

export const exportDeposition = async (
  [_, depositionId]: [_: string | null, depositionId?: string],
  { arg }: { arg: string }
) => {
  const res = await fetchWithAuth(
    `/depositions/${depositionId}/export?type=${arg}`,
    {},
    true
  );
  return res;
};

export const exportTimelineEvents = async (
  [_, matterId, filters]: [
    _: string | null,
    matterId: string,
    filters?: Omit<EventsFilters, 'pageSize' | 'page'>
  ],
  { arg }: { arg: string }
) => {
  const queryFilters = getQueryFilters(filters, '&');

  const res = await fetchWithAuth(
    `/matters/${matterId}/events/export?type=${arg}${queryFilters}`,
    {},
    true
  );
  return res;
};

export const updateDocument = async (
  _: string,
  {
    arg
  }: {
    arg: {
      documentId: number;
      body: Record<string, string>;
    };
  }
) => {
  return await fetchWithAuth(`/documents/${arg.documentId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arg.body)
  });
};

export const updateFact = async (
  key: string,
  { arg }: { arg: { factId: number; citation?: string; isHidden?: boolean } }
) => {
  const { factId, citation, isHidden } = arg;
  const res = await fetchWithAuth(`/facts/${factId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ citation, is_hidden: isHidden })
  });
  return res;
};

export const getLibrary = async (matterId: number, parentId?: number) => {
  const query = parentId ? `?parent_id=${parentId}` : '';
  const res = await fetchWithAuth(`/matters/${matterId}/library${query}`);
  return res;
};

export const getTasks = (showCompleted = false) => {
  return fetchWithAuth(`/matters/tasks?show_completed=${showCompleted}`);
};

export const updateTaskStatus = async (taskId: number, status: string) => {
  return await fetchWithAuth(`/matters/tasks/${taskId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      status
    })
  });
};

export const getMedicalSummary = async (
  id: string
): Promise<MedicalSummary> => {
  return fetchWithAuth(`/medical_summaries/${id}`);
};

export const getMedicalSummaryEvents = async (
  id: string
): Promise<MedicalSummaryEvent[]> => {
  return fetchWithAuth(`/medical_summaries/${id}/events`);
};

export const exportMedSummary = async (
  [_, id]: [_: string | null, id: string],
  { arg }: { arg: string }
) => {
  const res = await fetchWithAuth(
    `/medical_summaries/${id}/export?type=${arg}`,
    {},
    true
  );
  return res;
};

export const updateMedEvent = async (
  key: string,
  { arg }: { arg: { eventId: number; isHidden: boolean } }
) => {
  const { eventId, isHidden = false } = arg;
  const res = await fetchWithAuth(`/medical_summaries/events/${eventId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ is_hidden: isHidden })
  });
  return res;
};
