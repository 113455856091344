import { EventsFilters } from '@/utils/types';

const filterQuery: Record<string, string> = {
  docTypes: 'document_type',
  people: 'people',
  tags: 'tags',
  startDate: 'start_date',
  endDate: 'end_date'
};
export const getQueryFilters = (
  filters?: Omit<EventsFilters, 'pageSize' | 'page'>,
  initialOperator?: '&' | '?'
) => {
  let queries = '';
  Object.entries(filters ?? {}).forEach(([key, value]) => {
    const query = filterQuery[key];
    if (!value) return;
    const values = typeof value === 'string' ? [value] : value;
    return values.forEach((v) => {
      const operator = initialOperator
        ? initialOperator
        : queries.length
          ? '&'
          : '?';
      queries += operator + query + '=' + v;
    });
  });
  return queries;
};
