import React, { useEffect } from 'react';
import { Flex, Spin } from 'antd';
import { useRouter } from 'next/router';
import { useGetMattersMutation } from '@/http/queries';

const Home = () => {
  const router = useRouter();
  const { mutate } = useGetMattersMutation();

  useEffect(() => {
    // This is a hack for the next.js router to re-render the page, similar to
    // how react-router works. This is needed so page refresh will work correctly.
    // TODO: move to nextjs sst instead of static site
    if (router.asPath !== '/' && router.asPath !== '/login') {
      router.push(router.asPath);
      return;
    }

    mutate()
      .then((data) => {
        if (data?.matters) {
          router.push(`/matter/${data?.matters[0].id}`);
        }
      })
      .catch((e) => {
        if (e?.toString() == 'Error: Unauthorized') {
          router.push('/login');
        }
      });
  }, []);

  return (
    <Flex vertical align="center" justify="center" className="h-[100vh]">
      <Spin />
    </Flex>
  );
};

export default Home;
